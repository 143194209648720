import { virtualTrack, primeInviewEvent } from './tracking';
import { handleAdBlockDependentAction } from './adblock';
import { fetchForYouDataWrapper } from '../homepage/for-you';
import { insertMedianet } from './adInsertion';
import { updateAdParams, applyConfig } from './adUtilities';

/**
 * @see {@link useLegacyFunction} see the comment in the React hook for details
 */
(() => {
	window.legacyFunctions = {
		handleAdBlockDependentAction,
		virtualTrack,
		primeInviewEvent,
		fetchForYouDataWrapper,
		insertMedianet,
		updateAdParams,
		applyConfig,
	};

	window.dispatchEvent(new CustomEvent('legacyFunctionsLoaded'));
})();
